export const NavbarData = [
  // {
  //   title: "Hardware",
  //   path: "/hardware",
  // },
  // {
  //   title: "Learn",
  //   path: "/learn",
  // },
  {
    title: "Pricing",
    path: "/pricing",
  },
  // {
  //   title: "Contact Us",
  //   path: "/contactus",
  // },
];
