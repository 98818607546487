export const Resources = [
  {
    name: "Request Demo",
    link: "/requestdemo",
    id: 1,
  },
  {
    name: "Pricing",
    link: "/pricing",
    id: 2,
  },
  {
    name: "Support",
    link: "/contactus",
    id: 3,
  },
  {
    name: "Hardware",
    link: "/hardware",
    id: 4,
  },
  {
    name: "Learn",
    link: "/learn",
    id: 5,
  },
];

export const Company = [
  {
    name: "About Us",
    link: "/aboutus",
    id: 1,
  },
  {
    name: "Contact Us",
    link: "/contactus",
    id: 1,
  },
  {
    name: "Careers",
    link: "/careers",
    id: 1,
  },
  {
    name: "Blog ",
    link: "/blog",
    id: 1,
  },
  {
    name: "Data Security",
    link: "/datasecurity",
    id: 1,
  },
];
