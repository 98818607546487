export const products = [
  {
    title: "wVisitor",
    path: "/workwise",
  },
  {
    title: "Visitor management",
    path: "/visitor-management",
  },
  {
    title: "Meeting rooms",
    path: "/meeting-room",
  },
];

export const resources = [
  {
    title: "Hardware",
    path: "/hardware",
  },
  {
    title: "Learn",
    path: "/learn",
  },
  {
    title: "Contact Us",
    path: "/contactus",
  },
];
